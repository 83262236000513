import store from "@/store";
import {
  OPERATORTYPEVENUE,
  OPERATORTYPEVENDOR,
  OPERATORTYPEOUTLET,
  OPERATORTYPECOURIER,
} from "@/constants/operatorTypes.ts";

export default {
  namespaced: true,

  state: {
    selectedOperator: null,
  },

  actions: {
    // 💥 Attempt to use the provided operator. This will validate it against the back end.
    use({ commit }, operator) {
      return (
        store.state.apiPrivate.client.endpoints.user
          .validateOperation(operator)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            return Promise.reject(
              "[🎟️] Current user claims do not provide access to this operation."
            );
          })
          // The operation is valid. Use it.
          .then((data) => {
            data.type = data.type?.toLowerCase();

            commit("STORE_SELECTED_OPERATION", data);

            document.title = `Admin: ${data.name}`;

            return data;
          })
          .catch((e) => {
            window.log.error("[🎟️] Failed to validate operation access.", e);
          })
      );
    },

    // 💥 Recover the selected operator, from the state, if not the local storage, if not the available operations.
    async recover({ state }) {
      // If there is a operator already in the state, just return that.
      if (state.selectedOperator != null) {
        return state.selectedOperator;
      }

      // Otherwise, attempt to recover one from the saved state
      let selectedOperator;
      try {
        selectedOperator = JSON.parse(window.localStorage.getItem("operator"));

        // Check to make sure this data is valid.
        if (
          selectedOperator?.type != OPERATORTYPEVENUE &&
          selectedOperator?.type != OPERATORTYPEVENDOR &&
          selectedOperator?.type != OPERATORTYPEOUTLET &&
          selectedOperator?.type != OPERATORTYPECOURIER
        ) {
          throw new Error("Saved operator does not have a valid type.");
        }

        if (
          selectedOperator?.id == null ||
          isNaN(selectedOperator?.id) ||
          selectedOperator?.id <= 0
        ) {
          throw new Error("Saved operator does not have a valid ID.");
        }
      } catch (e) {
        selectedOperator = null;
        // ignored
      }

      let validOperator;

      // Attempt to use the operator we recovered.
      if (selectedOperator != null) {
        try {
          validOperator = await store.dispatch(
            "selectedOperator/use",
            selectedOperator
          );

          if (validOperator != null) {
            return validOperator;
          }
        } catch {
          // ignored
        }
      }

      // If this fails, reset to default.
      window.log.info("Saved operator is not available, resetting to default.");

      try {
        // Attempt to validate the default operator
        let defaultOperator = store.getters["availableOperators/getDefault"];

        if (defaultOperator != null) {
          validOperator = await store.dispatch(
            "selectedOperator/use",
            defaultOperator
          );

          if (validOperator != null) {
            return validOperator;
          }
        }
      } catch {
        // ignored
      }

      return null;
    },

    clear({ commit }) {
      commit("CLEAR");
    },
  },

  mutations: {
    // 🦠 Clear the selected operation
    CLEAR(state) {
      state.selectedOperator = null;

      window.localStorage.removeItem("operator");
    },

    // 🦠 Stores the selected operation
    STORE_SELECTED_OPERATION(state, operation) {
      state.selectedOperator = operation;

      window.localStorage.setItem(
        "operator",
        JSON.stringify({
          id: operation.id,
          type: operation.type,
        })
      );
    },
  },

  getters: {
    getId: (state) => state.selectedOperator?.id,
    getType: (state) => state.selectedOperator?.type,
    getName: (state) => state.selectedOperator?.name,
    getIdentity: (state) =>
      `${state.selectedOperator?.type}/${state.selectedOperator?.id}`,
  },
};
