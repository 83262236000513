import PrivateRepoStore from "./apiPrivateBaseStore.js";
import store from "@/store";

const componentRepoStore = new PrivateRepoStore("ApiComponentRepo", 1);

componentRepoStore.getters.getByOutletId = () => (outletId) => {
  return store.state.apiPrivate.client.endpoints.outletComponents
    .getAll(outletId)
    .then((response) => {
      if (response.status == 200) {
        return response.data.data;
      } else {
        return Promise.reject(
          `Failed to download component for outlet ${outletId}`
        );
      }
    })
    .then((data) => {
      data.forEach((component) => {
        store.dispatch("apiPrivateComponents/upsert", component);
      });

      return data;
    });
};

componentRepoStore.getters.getByItemId = () => (itemId) => {
  return store.state.apiPrivate.client.endpoints.items
    .getComponents(itemId)
    .then((response) => {
      if (response.status == 200) {
        return response.data.data;
      } else {
        return Promise.reject(
          `Failed to download component for item ${itemId}`
        );
      }
    });
};

componentRepoStore.getters.deleteById = () => (id) => {
  return store.state.apiPrivate.client.endpoints.components.delete(id);
};

export default componentRepoStore;
