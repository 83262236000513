import { mdiStadium, mdiDomain, mdiStorefront, mdiBicycle } from "@mdi/js";

export const OPERATORTYPEVENUE = "venue";
export const OPERATORTYPEVENDOR = "vendor";
export const OPERATORTYPEOUTLET = "outlet";
export const OPERATORTYPECOURIER = "courier";

export const OPERATORTYPEVENUEICON = mdiStadium;
export const OPERATORTYPEVENDORICON = mdiDomain;
export const OPERATORTYPEOUTLETICON = mdiStorefront;
export const OPERATORTYPECOURIERICON = mdiBicycle;
