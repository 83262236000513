import { createStore } from "vuex";
import { ZoneId } from "@js-joda/core";

import tokens from "@/store/security/tokens";
import claims from "@/store/security/claims";
import availableOperators from "@/store/operators/availableOperators";
import selectedOperator from "@/store/operators/selectedOperator";
import units from "@/store/units/apiPublicUnitEnums";
import unsaved from "@/store/unsaved";

import apiPublic from "@/store/public/apiPublic";
import apiPublicAllergens from "@/store/public/repos/apiPublicAllergens";
import apiPublicTaxRates from "@/store/public/repos/apiPublicTaxRates";

import apiPrivate from "@/store/private/apiPrivate";
import apiPrivateItems from "@/store/private/repos/apiPrivateItems";
import apiPrivateMenus from "@/store/private/repos/apiPrivateMenus";
import apiPrivateAllergens from "@/store/private/repos/apiPrivateAllergens";
import apiPrivateServices from "@/store/private/repos/apiPrivateServices";
import apiPrivateComponents from "@/store/private/repos/apiPrivateComponents";
import apiPrivateVenues from "@/store/private/repos/apiPrivateVenues";
import apiPrivateVendors from "@/store/private/repos/apiPrivateVendors";
import apiPrivateOutlets from "@/store/private/repos/apiPrivateOutlets";

export default createStore({
  state: {
    locale: "en-GB",
    currency: "GBP",
    timezone: "Europe/London",
  },

  mutations: {},

  actions: {},

  getters: {
    getTimezone: (state) => ZoneId.of(state.timezone),
  },

  modules: {
    tokens,
    claims,
    availableOperators,
    selectedOperator,
    units,
    unsaved,
    apiPublic,
    apiPublicAllergens,
    apiPublicTaxRates,
    apiPrivate,
    apiPrivateItems,
    apiPrivateMenus,
    apiPrivateAllergens,
    apiPrivateServices,
    apiPrivateComponents,
    apiPrivateVenues,
    apiPrivateVendors,
    apiPrivateOutlets,
  },
});
