import store from "@/store";
import {
  OPERATORTYPEVENUE,
  OPERATORTYPEVENDOR,
  OPERATORTYPECOURIER,
  OPERATORTYPEOUTLET,
} from "@/constants/operatorTypes.ts";

export default {
  namespaced: true,

  state: {
    available: {
      venues: [],
      vendors: [],
      couriers: [],
      outlets: [],
    },
  },

  actions: {
    // 💥 Download the list of company operators which are available to this user.
    download({ commit }) {
      // ✅ Get the company operators for the user token.
      return store.state.apiPrivate.client.endpoints.user
        .getOperations()
        .then((response) => {
          if (response.status == 200) {
            return response.data.data;
          } else if (response.status === 401) {
            store.dispatch("tokens/clear");
            window.log.info("[🎟️💾] Cleared tokens in local storage.");
          }

          return Promise.reject(
            "Failed to download company operations for user."
          );
        })
        .then((data) => {
          commit("STORE_OPERATIONS", data);
        });
    },
    clear({ commit }) {
      commit("CLEAR");
    },
  },

  mutations: {
    // 🦠 Clears the tokens, claims and company operations.
    CLEAR(state) {
      state.availableOperations = {
        venues: [],
        vendors: [],
        couriers: [],
        outlets: [],
      };
    },

    // 🦠 Stores the available operation
    STORE_OPERATIONS(state, payload) {
      if (payload.venues != null && payload.venues.length) {
        for (let i = 0; i < payload.venues.length; i++) {
          payload.venues[i].type = OPERATORTYPEVENUE;
        }
      }

      if (payload.vendors != null && payload.vendors.length) {
        for (let i = 0; i < payload.vendors.length; i++) {
          payload.vendors[i].type = OPERATORTYPEVENDOR;
        }
      }

      if (payload.outlets != null && payload.outlets.length) {
        for (let i = 0; i < payload.outlets.length; i++) {
          payload.outlets[i].type = OPERATORTYPEOUTLET;
        }
      }

      if (payload.couriers != null && payload.couriers.length) {
        for (let i = 0; i < payload.couriers.length; i++) {
          payload.couriers[i].type = OPERATORTYPECOURIER;
        }
      }

      state.available = payload;
    },
  },

  getters: {
    get: (state) =>
      (state.available?.venues ?? [])
        .concat(state.available?.vendors ?? [])
        .concat(state.available?.outlets ?? [])
        .concat(state.available?.couriers ?? [])
        .sort(),

    getVenues: (state) => state.available?.venues ?? [],

    getVendors: (state) => state.available?.vendors ?? [],

    getCouriers: (state) => state.available?.couriers ?? [],

    getOutlets: (state) => state.available?.outlets ?? [],

    /**
     * Get the default operation for a given user.
     */
    getDefault: () => {
      let vendor = store.getters["availableOperators/getVendorFirstOrNull"];
      if (vendor != null) {
        return vendor;
      }

      let venue = store.getters["availableOperators/getVenueFirstOrNull"];
      if (venue != null) {
        return venue;
      }

      let courier = store.getters["availableOperators/getCourierFirstOrNull"];
      if (courier != null) {
        return courier;
      }

      let outlet = store.getters["availableOperators/getOutletFirstOrNull"];
      if (outlet != null) {
        return outlet;
      }

      return null;
    },

    getTotal: (state) => {
      return (
        state.available?.venues.length +
        state.available?.vendors.length +
        state.available?.couriers.length +
        state.available?.outlets.length
      );
    },

    getVenueFirstOrNull: (state) =>
      state.available?.venues?.length
        ? state.available.venues[0]
        : null ?? null,

    getVendorFirstOrNull: (state) =>
      state.available?.vendors?.length
        ? state.available.vendors[0]
        : null ?? null,

    getCourierFirstOrNull: (state) =>
      state.available?.couriers?.length
        ? state.available.couriers[0]
        : null ?? null,

    getOutletFirstOrNull: (state) =>
      state.available?.outlets?.length
        ? state.available.outlets[0]
        : null ?? null,
  },
};
