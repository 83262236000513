import router from "@/router";

// TODO: This currently only supports a single lock, so if a view adds a lock, then a modal adds another, when the modal lock is cleared, the view lock will be lost too.
// Instead switch to an array of named locks, so you go                     dispatch('unsaved/lock', { name: menu.name })
// The router method would then show "There appear to be changes in the following item(s): Burger Menu, Burger Item"
// And from a script perspective, we can handle single checks with          dispatch('unsaved/checkLock', { name: menu.name }).then()

export default {
  namespaced: true,

  state: {
    unsavedChanges: false,
    showNavigationModal: false,
    navigationTarget: {},
  },

  actions: {
    // 💥 Initalize the state
    initialize({ commit }) {
      commit("INITIALIZE");
    },

    // 💥
    setUnsaved({ commit }) {
      commit("SET_UNSAVED");
    },

    // 💥
    setSaved({ commit }) {
      commit("SET_SAVED");
    },

    // 💥
    setChanges({ commit }, hasChanged) {
      hasChanged ? commit("SET_UNSAVED") : commit("SET_SAVED");
    },

    // 💥
    navigateTo({ commit, state }, payload) {
      if (state.unsavedChanges && !payload.disableModal) {
        commit("NAVIGATE_TO", payload);
      }
    },

    // 💥
    cancelNavigation({ commit }) {
      commit("CANCEL_NAVIGATION");
    },

    // 💥
    discardChanges({ commit }) {
      commit("DISCARD_CHANGES");
    },
  },

  mutations: {
    // 🦠 Initalize the state
    INITIALIZE(state) {
      window.onbeforeunload = () => {
        if (state.unsavedChanges) {
          return "There are unsaved changes. Are you sure you want to close?";
        }
      };
    },

    // 🦠
    SET_UNSAVED(state) {
      state.unsavedChanges = true;
    },

    // 🦠
    SET_SAVED(state) {
      state.unsavedChanges = false;
    },

    // 🦠
    NAVIGATE_TO(state, payload) {
      state.navigationTarget = payload.target;
      state.showNavigationModal = true;
    },

    // 🦠
    CANCEL_NAVIGATION(state) {
      state.navigationTarget = {};
      state.showNavigationModal = false;
    },

    // 🦠
    DISCARD_CHANGES(state) {
      let navigationTarget = state.navigationTarget;

      state.unsavedChanges = false;
      state.navigationTarget = {};
      state.showNavigationModal = false;

      router.push(navigationTarget);
    },
  },

  getters: {
    unsavedChanges: (state) => state.unsavedChanges,
    showNavigationModal: (state) => state.showNavigationModal,
  },
};
