import PrivateRepoStore from "./apiPrivateBaseStore.js";
import store from "@/store";

const menuRepoStore = new PrivateRepoStore("ApiMenuRepo", 1);

menuRepoStore.mutations.SETMETA = (state, payload) => {
  state.repo.setMeta(payload.id, payload.data);
};

menuRepoStore.actions.setMeta = ({ commit }, payload) => {
  commit("SETMETA", payload);
};

menuRepoStore.getters.getMetaById = (state) => (id) => {
  return state.repo.getMetaById(id);
};

menuRepoStore.getters.getByOutletId = () => (outletId) => {
  return store.state.apiPrivate.client.endpoints.outletMenus
    .getAll(outletId)
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      } else {
        return Promise.reject(
          `Failed to download menus for outlet ${outletId}`
        );
      }
    })
    .then((data) => {
      data.data.forEach((menu) => {
        store.dispatch("apiPrivateMenus/upsert", menu);
        store.dispatch("apiPrivateMenus/setMeta", {
          id: menu.id,
          data: data,
        });
      });

      return data.data.map((x) => x.id);
    })
    .then((ids) => {
      return ids.map((x) => store.getters["apiPrivateMenus/getMetaById"](x));
    });
};

menuRepoStore.getters.deleteById = (state) => (id) => {
  return state.repo.deleteById(id);
};

export default menuRepoStore;
