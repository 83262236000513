import {
  DateTimeFormatter,
  LocalDateTime,
  LocalTime,
  LocalDate,
} from "@js-joda/core";
require("@js-joda/timezone");
import store from "@/store";

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        getCdnFile(file) {
          return process.env.VUE_APP_CDN + file;
        },

        formatMoney(money) {
          if (money == null || isNaN(money)) {
            return "";
          }

          return new Intl.NumberFormat(store.state.locale, {
            style: "currency",
            currency: store.state.currency,
          }).format(money);
        },

        formatPercent(percent, decimalPlaces = 2) {
          if (percent == null || isNaN(percent)) {
            return "";
          }

          return percent.toFixed(decimalPlaces) + "%";
        },

        formatDateTime(
          dateTime,
          format = "dd/MM/yyyy HH:mm",
          fallback = "--/--/---- --:--"
        ) {
          try {
            if (dateTime == null) {
              return fallback;
            }

            const localDateTime = LocalDateTime.parse(dateTime);
            const timezone = this.$store.getters.getTimezone;
            const zonedDateTime = localDateTime.atZone(timezone);

            const formatter = DateTimeFormatter.ofPattern(format);

            return zonedDateTime.format(formatter);
          } catch (err) {
            window.log.error(
              "[🗓️❌] Failed to process date time, the user may see unformatted data.",
              err
            );

            return dateTime;
          }
        },

        formatDate(date, format = "dd/MM/yyyy") {
          if (date == null) {
            return "--/--/----";
          }

          const formatter = DateTimeFormatter.ofPattern(format);
          const localDate = LocalDate.parse(date);
          return localDate.format(formatter);
        },

        formatTime(time, format = "HH:mm") {
          if (time == null) {
            return "--:--";
          }

          const formatter = DateTimeFormatter.ofPattern(format);
          const localTime = LocalTime.parse(time);
          return localTime.format(formatter);
        },

        formatDay(date) {
          if (date === null) {
            return "-";
          }

          const dateName = new Date(date).toLocaleString("en-us", {
            weekday: "short",
          });
          return dateName;
        },
      },
    });
  },
};
