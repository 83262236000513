import store from "@/store";

export default {
  namespaced: true,

  state: {
    units: [],
  },
  mutations: {
    STORE_UNITS(state, payload) {
      state.units = payload;
    },
  },
  actions: {
    async getUnits({ commit, state }) {
      if (state.units.length === 0) {
        const response =
          await store.state.apiPublic.client.endpoints.unitEnums.get();
        commit("STORE_UNITS", response.data.data);
      }
    },
  },
  getters: {
    getAll: (state) => state.units,
  },
};
