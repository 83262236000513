<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
  export default {
    errorCaptured(err, vm, info) {
      window.log.error(
        `An unhandled exception was caught on the main App component.\n${err.message}\n`,
        {
          err,
          vm,
          info,
        }
      );

      return false;
    },
  };
</script>

<style lang="scss">
  .main-content {
    @include themed() {
      color: t($text);
      background: t("back-content");
    }

    display: flex;
    flex-direction: column;
    margin-top: $topbar_height;
    margin-left: 0;
    min-height: calc(100vh - $topbar_height);
    padding: 1rem 2rem 2rem;
    position: relative;
    font-size: 0.9rem;
    transition: margin-left 0.4s;

    @media screen and (max-width: $vertical_tablet_width) {
      min-height: calc(100vh - $topbar_height - 2.5rem);
    }

    .sidebar-visible & {
      margin-left: $sidebar_width;
    }

    .mobile-layout & {
      padding: 1rem;
    }

    .mobile-layout.sidebar-visible & {
      margin-left: 0;
    }

    .has-expandable-panel & {
      padding-right: 420px;
    }

    .mobile-layout.has-expandable-panel & {
      padding-right: 1rem;
    }
  }

  .layout-main-container {
    margin-left: $sidebar_width;

    .mobile-layout & {
      margin-left: 0;
    }
  }
</style>
