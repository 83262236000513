import store from "@/store";
import * as apiPublic from "@tucktrucks/platform-base-public";

export default {
  namespaced: true,

  state: {
    client: null,
    networkError: false,
    networkErrorLast: {},
  },

  mutations: {
    INSTANTIATE_CLIENT(state) {
      if (state.client === null) {
        state.client = new apiPublic.Client();
        state.client.domain = process.env.VUE_APP_PUBLIC_API_DOMAIN;
        state.client.sessionId = window.sessionId;
        state.client.fingerprint = window.fingerprint;

        // Catches all errors, does not include response info.
        //state.client.onError = (error) => { window.log.error("[api]", error); }

        // Catches axios errors, includes status and response - Note, this will include intentional 404 responses etc.
        //state.client.onAxiosError = (status, error) => { window.log.error("[api.axios]", status, error) }

        // Catches axios from 400-499, includes status and response - Note, this will include intentional 404 responses etc.
        //state.client.onClientError = (status, error) => { window.log.warn("[api.client]", status, error) }

        // Catches axios from 500-599, includes status and response
        //state.client.onServerError = (status, error) => { window.log.error("[api.server]", status, error); };

        // Catches network errors, i.e. server ignored request, includes response - this can be used to detect lack of connectivity
        state.client.onNetworkError = (error) => {
          store.dispatch("apiPublic/onNetworkError", error);
        };

        store.dispatch("apiPublicAllergens/instantiate");
        store.dispatch("apiPublicTaxRates/instantiate");
      }
    },
    ON_NETWORK_ERROR(state, error) {
      if (error.data != null && error.status == 200) {
        state.networkError = false;
        state.networkErrorLast = error;
      } else {
        state.networkError = true;
        state.networkErrorLast = error;
      }
    },
  },

  actions: {
    instantiateClient({ commit }) {
      commit("INSTANTIATE_CLIENT");
    },

    onNetworkError({ commit }, error) {
      commit("ON_NETWORK_ERROR", error);
    },
  },

  getters: {
    getNetworkError: (state) => {
      return state.networkError;
    },
  },
};
