import PrivateRepoStore from "./apiPrivateBaseStore.js";
import store from "@/store";

const serviceRepoStore = new PrivateRepoStore("ApiServiceRepo", 1);

serviceRepoStore.getters.getByOutletId = () => (outletId) => {
  return store.state.apiPrivate.client.endpoints.outletServices
    .get(outletId)
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      } else {
        return Promise.reject(
          `Failed to download menus for vendor ${outletId}`
        );
      }
    });
};

serviceRepoStore.getters.getRecentByOutletId = () => (outletId) => {
  return store.state.apiPrivate.client.endpoints.outletServices
    .getRecent(outletId)
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      } else {
        return Promise.reject(
          `Failed to download menus for vendor ${outletId}`
        );
      }
    });
};

export default serviceRepoStore;
