import jwt_decode from "jwt-decode";

export default {
  namespaced: true,

  state: {
    tokens: {
      header: {},
      body: {},
    },
  },

  actions: {
    // 💥
    useTokens({ commit }, tokens) {
      if (tokens == null) {
        return Promise.reject("[🎟️] No stored token to decode.");
      }

      // Attempt to parse the provided token.
      let claims;
      try {
        claims = {
          header: jwt_decode(tokens.bearer, { header: true }),
          body: jwt_decode(tokens.bearer),
        };

        commit("STORE_CLAIMS", claims);

        return true;
      } catch {
        commit("CLEAR");

        Promise.reject("[🎟️] Failed to decode the provided token.");

        return false;
      }
    },
  },

  mutations: {
    // 🦠 Clears the tokens, claims and company operations.
    CLEAR(state) {
      state.tokens = {
        header: {},
        body: {},
      };
    },

    // 🦠 Stores the claims.
    STORE_CLAIMS(state, tokens) {
      state.tokens = tokens;
    },
  },

  getters: {
    actor: (state) => state.tokens?.body?.act,

    name: (state) => state.tokens?.body?.name,

    role: (state) => state.tokens?.body?.role?.toLowerCase(),

    userDisplayName: (state) => {
      const displayName = state.tokens?.body.displayName;

      if (displayName == null || displayName.length == 0) {
        return state.tokens?.body.name;
      }

      return displayName ?? "User";
    },
  },
};
