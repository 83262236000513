import router from "@/router";

export default {
  namespaced: true,

  state: {
    tokens: null,
  },

  actions: {
    // 💥 Store the tokens in the local storage + state
    storeTokens({ commit }, tokens) {
      commit("STORE_TOKENS", tokens);
    },

    // 💥 Clears the tokens and returns the user to the login page.
    logout({ commit }) {
      window.log.info("[🎟️] Logged out.");
      commit("CLEAR");

      document.title = "Tuck Trucks Admin";

      router.push("/login");
    },

    clear({ commit }) {
      commit("CLEAR");
    },

    // 💥 Attempts to recover the tokens from the state, if not the local storage.
    recover({ commit, state }) {
      // Grab the tokens from the state
      if (state.tokens != null) {
        return state.tokens;
      }

      try {
        let tokens = JSON.parse(window.domainCookie.get("authentication"));

        if (tokens?.bearer != null) {
          window.log.info("[🎟️💾] Recovered tokens from local storage.");

          commit("STORE_TOKENS", tokens);

          return tokens;
        }
      } catch {
        // ignored
      }

      return Promise.reject("Failed to recover an existing token.");
    },
  },

  mutations: {
    // 🦠 Clears the tokens
    CLEAR(state) {
      state.tokens = null;

      window.domainCookie.remove("authentication");
    },

    // 🦠 Stores the tokens.
    STORE_TOKENS(state, tokens) {
      state.tokens = tokens;

      window.domainCookie.set("authentication", JSON.stringify(tokens));
    },
  },

  getters: {
    role: (state) => state.tokens?.body?.role?.toLowerCase(),
  },
};
