import store from "@/store";
import * as apiPrivate from "@tucktrucks/platform-base-private";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    client: null,
  },

  actions: {
    instantiateClient({ commit }) {
      commit("INSTANTIATE_CLIENT");
    },

    useTokens({ state, commit }, tokens) {
      if (state.client == null || !state.client.connected) {
        commit("INSTANTIATE_CLIENT");
      }

      state.client.useTokens(tokens.bearer, tokens.refresh);
    },
  },

  mutations: {
    INSTANTIATE_CLIENT(state) {
      if (state.client == null) {
        state.client = new apiPrivate.Client();
        state.client.domain = process.env.VUE_APP_PRIVATE_API_DOMAIN;
        state.client.sessionId = window.sessionId;
        state.client.fingerprint = window.fingerprint;

        state.client.onRefresh = (tokens) => {
          window.log.info("[🎟️] Refreshed token successfully.", tokens);
        };

        state.client.onRefreshFailed = () => {
          window.log.info("[🎟️] Token refresh failed.");

          store.dispatch("tokens/logout");
          store.dispatch("selectedOperator/clear");
        };

        // Catches all errors, does not include response info.
        //state.client.onError = (error) => { window.log.error("[api]", error); }

        // Catches axios errors, includes status and response - Note, this will include intentional 404 responses etc.
        //state.client.onAxiosError = (status, error) => { window.log.error("[api.axios]", status, error) }

        // Catches axios from 400-499, includes status and response - Note, this will include intentional 404 responses etc.
        state.client.onClientError = (status /*error*/) => {
          //window.log.warn("[api.client]", status, error)

          if (status == 401) {
            if (router.currentRoute._value.fullPath != "/login") {
              router.push("/login");
            }
          }
        };

        // Catches axios from 500-599, includes status and response
        //state.client.onServerError = (status, error) => { window.log.error("[api.server]", status, error); };

        // Catches network errors, i.e. server ignored request, includes response - this can be used to detect lack of connectivity
        state.client.onNetworkError = (error) => {
          //window.log.error("[api]", error)
          store.dispatch("apiPublic/onNetworkError", error);
        };

        store.dispatch("apiPrivateItems/instantiate");
        store.dispatch("apiPrivateMenus/instantiate");
        store.dispatch("apiPrivateAllergens/instantiate");
        store.dispatch("apiPrivateServices/instantiate");
        store.dispatch("apiPrivateComponents/instantiate");
        store.dispatch("apiPrivateVenues/instantiate");
        store.dispatch("apiPrivateVendors/instantiate");
        store.dispatch("apiPrivateOutlets/instantiate");
      }
    },
  },
};
