import { DateTimeFormatter, LocalDate } from "@js-joda/core";

export const isValidDate = (dateToValidate) => {
  try {
    const pattern = "yyyy-MM-dd";
    const format = DateTimeFormatter.ofPattern(pattern);
    LocalDate.parse(dateToValidate, format);
  } catch {
    return false;
  }

  return true;
};

export const loadDefaults = () => {
  try {
    const defaults = JSON.parse(window.sessionStorage.getItem("defaultDates"));

    if (defaults.dateFrom != null && defaults.dateTo != null) {
      return defaults;
    }
  } catch {
    // ignored
  }

  const now = LocalDate.now();
  const pattern = DateTimeFormatter.ofPattern("yyyy-MM-dd");

  return {
    dateFrom: now
      .minusDays(LocalDate.now().dayOfWeek().ordinal())
      .format(pattern),
    dateTo: now.format(pattern),
  };
};

export const loadDefaultFrom = () => {
  try {
    const defaults = JSON.parse(window.sessionStorage.getItem("defaultDates"));

    if (defaults.dateFrom != null) {
      return defaults.dateFrom;
    }
  } catch {
    // ignored
  }

  const now = LocalDate.now();
  const pattern = DateTimeFormatter.ofPattern("yyyy-MM-dd");

  return now.minusDays(LocalDate.now().dayOfWeek().ordinal()).format(pattern);
};

export const loadDefaultTo = () => {
  try {
    const defaults = JSON.parse(window.sessionStorage.getItem("defaultDates"));

    if (defaults.dateTo != null) {
      return defaults.dateTo;
    }
  } catch {
    // ignored
  }

  const now = LocalDate.now();
  const pattern = DateTimeFormatter.ofPattern("yyyy-MM-dd");

  return now.format(pattern);
};

export const saveDefaults = (dateFrom, dateTo) => {
  window.sessionStorage.setItem(
    "defaultDates",
    JSON.stringify({ dateFrom: dateFrom, dateTo: dateTo })
  );
};
