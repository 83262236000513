import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { loadDefaults } from "@/helpers/dateTimes";

store.dispatch("unsaved/initialize");

function getDefaultParams(to) {
  const defaults = loadDefaults();

  return {
    operatorType: to.params.operatorType,
    operatorId: to.params.operatorId,
    dateFrom: defaults.dateFrom,
    dateTo: defaults.dateTo,
  };
}

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/views/Main.vue"),
    children: [
      // ***************************************************************************
      // DASHBOARD / EVENTS / SERVICES
      {
        name: "/dashboard",
        path: "/:operatorType?/:operatorId(\\d+)?/dashboard",
        meta: { scoped: true, area: "/dashboard" },
        component: () => import("@/views/Dashboard/Dashboard.vue"),
      },
      {
        name: "/event",
        path: "/:operatorType?/:operatorId(\\d+)?/event/:eventId",
        meta: { scoped: true, area: "/dashboard" },
        component: () => import("@/views/Dashboard/EventDetail.vue"),
      },
      {
        name: "/services",
        path: "/:operatorType?/:operatorId(\\d+)?/services",
        meta: { scoped: true, area: "/dashboard" },
        component: () => import("@/views/Dashboard/Services.vue"),
      },
      {
        name: "/service",
        path: "/:operatorType?/:operatorId(\\d+)?/service/:serviceId",
        meta: { scoped: true, area: "/dashboard" },
        component: () => import("@/views/Dashboard/ServiceDetail.vue"),
      },
      {
        name: "/service/statement",
        path: "/:operatorType?/:operatorId(\\d+)?/service/:serviceId/statement",
        meta: { scoped: true, area: "/dashboard" },
        component: () => import("@/views/Dashboard/ServiceStatement.vue"),
      },

      // ***************************************************************************
      // SALES
      // Summary
      {
        name: "/sales/summary",
        path: "/:operatorType?/:operatorId(\\d+)?/sales/summary",
        meta: { scoped: true },
        redirect: (to) => {
          return {
            name: "/sales/summary/dates",
            params: getDefaultParams(to),
          };
        },
      },
      {
        name: "/sales/summary/dates",
        path: "/:operatorType?/:operatorId(\\d+)?/sales/summary/:dateFrom/:dateTo",
        meta: { scoped: true },
        component: () => import("@/views/Sales_/Summary.vue"),
      },

      // ***************************************************************************
      // Performance
      {
        name: "/sales/performance",
        path: "/:operatorType?/:operatorId(\\d+)?/sales/performance",
        meta: { scoped: true },
        redirect: (to) => {
          return {
            name: "/sales/performance/dates",
            params: getDefaultParams(to),
          };
        },
      },
      {
        name: "/sales/performance/dates",
        path: "/:operatorType?/:operatorId(\\d+)?/sales/performance/:dateFrom/:dateTo",
        meta: { scoped: true },
        component: () => import("@/views/Sales_/Performance.vue"),
      },

      // ***************************************************************************
      // Transactions
      {
        name: "/sales/transactions",
        path: "/:operatorType?/:operatorId(\\d+)?/transactions",
        meta: { scoped: true },
        redirect: (to) => {
          return {
            name: "/sales/transactions/dates",
            params: getDefaultParams(to),
          };
        },
      },
      {
        name: "/sales/transactions/dates",
        path: "/:operatorType?/:operatorId(\\d+)?/transactions/:dateFrom/:dateTo",
        meta: { scoped: true },
        component: () => import("@/views/Sales_/Transactions.vue"),
      },

      // ***************************************************************************
      // Transactions
      {
        name: "/refunds",
        path: "/:operatorType?/:operatorId(\\d+)?/refunds",
        meta: { scoped: true },
        component: () => import("@/views/Refunds.vue"),
      },
      {
        name: "/menus",
        path: "/:operatorType?/:operatorId(\\d+)?/menus",
        meta: { scoped: true },
        component: () => import("@/views/Menus/Menus.vue"),
      },
      {
        name: "/menu-detail",
        path: "/:operatorType?/:operatorId(\\d+)?/menu/:menuId(\\d+)",
        meta: { scoped: true },
        component: () => import("@/views/Menus/MenuDetail.vue"),
      },
      {
        name: "/items",
        path: "/:operatorType?/:operatorId(\\d+)?/items",
        meta: { scoped: true },
        component: () => import("@/views/Menus/Items.vue"),
      },
      {
        name: "/components",
        path: "/:operatorType?/:operatorId(\\d+)?/components",
        meta: { scoped: true },
        component: () => import("@/views/Menus/Components.vue"),
      },
      {
        name: "/service-statement",
        path: "/:operatorType?/:operatorId(\\d+)?/service-statement",
        meta: { scoped: true },
        component: () => import("@/views/Statements/ServiceStatement.vue"),
      },
      {
        name: "/event-statement",
        path: "/:operatorType?/:operatorId(\\d+)?/event-statement",
        meta: { scoped: true },
        component: () => import("@/views/Statements/EventStatement.vue"),
      },
      {
        name: "/payout-statement",
        path: "/:operatorType?/:operatorId(\\d+)?/payout-statement",
        meta: { scoped: true },
        component: () => import("@/views/Statements/PayoutStatement.vue"),
      },

      // ***************************************************************************
      // Invoices
      {
        name: "/invoices",
        path: "/:operatorType?/:operatorId(\\d+)?/invoices",
        meta: { scoped: true },
        component: () => import("@/views/Statements/InvoiceStatement.vue"),
      },
      {
        name: "/invoices/dates",
        path: "/:operatorType?/:operatorId(\\d+)?/invoices/:dateFrom/:dateTo",
        meta: { scoped: true },
        component: () => import("@/views/Statements/InvoiceStatement.vue"),
      },

      // ***************************************************************************
      // Admin
      {
        name: "/admin",
        path: "/admin",
        meta: { scoped: false },
        component: () => import("@/views/Admin/Admin.vue"),
      },
      {
        name: "/admin/users",
        path: "/admin/users",
        meta: { scoped: false },
        component: () => import("@/views/Admin/Users.vue"),
      },
      {
        name: "/admin/adhoc",
        path: "/admin/adhoc",
        meta: { scoped: false },
        component: () => import("@/views/Admin/Adhoc.vue"),
      },
      {
        name: "/admin/entities",
        path: "/admin/entities",
        meta: { scoped: false },
        component: () => import("@/views/Admin/Entities.vue"),
      },
      {
        name: "/admin/reports/invoices",
        path: "/admin/reports/invoices",
        meta: { scoped: false },
        component: () => import("@/views/Admin/Reports/Invoices.vue"),
      },
      {
        name: "/admin/reports/revenue",
        path: "/admin/reports/revenue",
        meta: { scoped: false },
        component: () => import("@/views/Admin/Reports/Revenue.vue"),
      },
    ],
  },
  {
    path: "/login",
    meta: {
      allowAnonymous: true,
      autoLogonRedirect: "/dashboard",
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/:catchAll(.*)",
    meta: {
      allowAnonymous: true,
    },
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  // Reset scroll to top unless the a hash link is used.
  scrollBehavior(to, from, savedPosition) {
    // Allow hash links to go through unmodified
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }

    window.log.log(
      `[🧭] ${from.path} ➡️ ${to.path} [${savedPosition?.left ?? 0}, ${
        savedPosition?.top ?? 0
      }]`
    );

    // always scroll to top
    return { top: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  // ------------------------------------------------------------------------
  // Check for Unsaved Changes on current view
  if (store.getters["unsaved/unsavedChanges"]) {
    window.log.info(
      `[🧭🤚] Unsaved changes, preventing navigation to ${to.fullPath}.`
    );

    store.dispatch("unsaved/navigateTo", {
      disableModal: false,
      target: to,
    });

    next(false);
    return;
  }
  // ------------------------------------------------------------------------

  // Grab some values for if the route we're heading to allows anonymous, or if it has a logon redirect defined.
  let allowAnonymous = to.matched.some((record) => record.meta.allowAnonymous);
  let autoLogonRedirect = to.meta.autoLogonRedirect;
  let scoped = to.matched.some((record) => record.meta.scoped);

  // If the route allows anon access, and we're NOT defining a logon redirect route, allow the user in.
  if (allowAnonymous && autoLogonRedirect == null) {
    window.log.info("[🕵️] Anonymous endpoint, token system skipped.");

    next();
    return;
  }
  // ------------------------------------------------------------------------

  // This endpoint either requires authentication, or will redirect you onwards if you're already logged in.
  await store
    .dispatch("tokens/recover")
    // There was a token in storage, we've recovered it, and now need to inject it into things.
    .then(async (token) => {
      // Inject the apiPrivate client with the new tokens.
      await store.dispatch("apiPrivate/useTokens", token);

      // Inject the claims module with the new tokens.
      await store.dispatch("claims/useTokens", token);

      // Download the available operations for this user.
      // ⚠️ If this fails, the user will be automatically logged out.
      await store.dispatch("availableOperators/download");

      let redirect = null;

      if (scoped) {
        // This is a scoped endpoint we are going to, so recover the operator.
        var operator = await store.dispatch("selectedOperator/recover");

        if (operator != null) {
          // Assuming there is a valid operator we have recovered, check to see if this scoped endpoint is targetting the correct operator.
          if (
            to.params.operatorType == null ||
            to.params.operatorType == "" ||
            to.params.operatorType.toLowerCase() !=
              operator?.type?.toLowerCase() ||
            to.params.operatorId == null ||
            to.params.operatorId == "" ||
            to.params.operatorId != operator?.id
          ) {
            // If not, we create a redirect object which targets the correct view.
            redirect = {
              name: autoLogonRedirect != null ? autoLogonRedirect : to.name,
              params: {
                operatorType: operator.type.toLowerCase(),
                operatorId: operator.id,
              },
            };
          }
        } else {
          // We couldn't find an operator and this endpoint is scoped. Reject.
          return Promise.reject(
            "Failed to recover an operator for this scoped endpoint."
          );
        }
      } else {
        // If it's not scoped, and there is a redirect assigned, apply it here, otherwise don't worry.
        if (autoLogonRedirect != null) {
          router.push({ name: autoLogonRedirect });
        }
      }

      // We defined a redirect as part of the previous process, so apply it here.
      if (redirect != null) {
        router.push(redirect);
      }
    })
    // Failed to recover a token.
    .catch(() => {
      if (!allowAnonymous) {
        // This endpoint needs a token, so force logout.
        store.dispatch("tokens/logout");
        store.dispatch("selectedOperator/clear");

        // TODO: Improve this - forces logout to be final with a redirect, prevents hung up logon
        window.location.href = "/login";
      }
    });

  // We're done with the security!
  next();
});

export default router;
