import PrivateRepoStore from "./apiPrivateBaseStore.js";
import store from "@/store";

const itemRepoStore = new PrivateRepoStore("ApiItemRepo", 1);

itemRepoStore.getters.getByMenu = () => async (menu) => {
  let items = [];
  let promises = [];

  menu.sections.forEach((section) => {
    section.items.forEach((item) => {
      promises.push(
        store.getters["apiPrivateItems/getById"](item.id).then((item2) => {
          if (item2 != null) {
            items.push(item2);
          }
        })
      );
    });
  });

  await Promise.all(promises);

  return items;
};

itemRepoStore.getters.getByOutletId = () => (outletId) => {
  return store.state.apiPrivate.client.endpoints.outletItems
    .getAll(outletId)
    .then((response) => {
      if (response.status == 200) {
        return response.data.data;
      } else {
        return Promise.reject(
          `Failed to download items for outlet ${outletId}`
        );
      }
    })
    .then((data) => {
      let result = [];

      data.forEach((item) => {
        if (item != null) {
          store.dispatch("apiPrivateItems/upsert", item);
          result.push(item);
        }
      });

      return result;
    });
};

itemRepoStore.getters.deleteById = (state) => (id) => {
  return state.repo.deleteById(id);
};

export default itemRepoStore;
